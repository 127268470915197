import React, { Component } from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

class LinkBreadCrumb extends Component {
    render() {
        return (
            <div className='container mt-3'>
                <Breadcrumb>
                    <Link className='breadcrumb-item' to='/'>
                        Home
                    </Link>
                    <Link className='breadcrumb-item' to='/courses'>
                        Courses
                    </Link>
                    <Link className='breadcrumb-item' to='/upload'>
                        Upload
                    </Link>
                </Breadcrumb>
            </div>
        )
    }
}

const mapStateToProps = ({ auth }) => {
    return {
        email: auth.email
    }
}

export default connect(mapStateToProps)(LinkBreadCrumb);