import React, { Component } from "react";
import axios from "axios";
import slugify from "slugify";
import { Progress, CustomInput } from "reactstrap";
import { Redirect } from "react-router-dom";

import { connect } from "react-redux";
import { URL_API } from "../support/url/api_url";

class Login extends Component {
  state = {
    // labelVideo: 'Upload Video (.mp4)',
    error: "",
    success: "",
    showUpload: false,
    loading: false,

    // video
    fileVideoDB: null,
    fileVideoName: "Upload Video (.mp4)",
    uploadProgress: 0,

    fileImageDB: null,
    fileImageName: "Masukkan Gambar Thumbnail Cover",
  };
  handleVideo = (e) => {
    this.setState({
      error: "",
      success: "",
    });
    if (e.target.files[0].type !== "video/mp4") {
      this.setState({
        fileVideoDB: null,
        fileVideoName: "Upload Video (.mp4)",
        error: "Hanya menerima file video dengan extensi .mp4",
      });
    } else {
      this.setState({
        fileVideoDB: e.target.files[0],
        fileVideoName: e.target.files[0].name,
      });
    }
  };

  handleImage = (e) => {
    if (e.target.files[0]) {
      this.setState({
        fileImageDB: e.target.files[0],
        fileImageName: e.target.files[0].name,
      });
    } else {
      this.setState({
        fileImageDB: null,
        fileImageName: "Masukkan Gambar",
      });
    }
  };

  SendDataToServer = () => {
    // console.log(e.target.files[0])
    this.setState({
      error: "",
      loading: true,
      success: "",
    });
    // type: "video/mp4"
    if (this.state.fileVideoDB === null || this.Title.value === "") {
      if (this.state.fileVideoDB === null && this.Title.value === "") {
        this.setState({
          error:
            "Judul video tidak boleh kosong dan pilih file video yang ingin diupload (.mp4)",
          loading: false,
        });
      } else if (this.Title.value === "") {
        this.setState({
          error: "Judul video tidak boleh kosong",
          loading: false,
        });
      } else {
        this.setState({
          error: "Hanya menerima file video dengan extensi .mp4",
          loading: false,
        });
      }
    } else {
      // Mulai kirim video ke server
      this.setState({
        showUpload: true,
      });

      const token = localStorage.getItem("token");
      const formData = new FormData();
      const options = {
        onUploadProgress: (progressEvent) =>
          this.setState({
            uploadProgress: Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            ),
          }),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      let slug = slugify(this.Title.value, {
        replacement: "-",
        remove: /[*+~.()/$'"!;':@,?#><~`$^-_=+]/g,
        lower: true,
      });

      const data = {
        title: this.Title.value,
        slug,
      };

      console.log(this.state.fileVideoDB);

      formData.append("data", JSON.stringify(data));
      formData.append("courses_video", this.state.fileVideoDB);

      formData.append("image_video", this.state.fileImageDB);

      axios
        .post(`${URL_API}/user/uploadvideo`, formData, options)
        .then((res) => {
          this.setState({
            loading: false,
            success: "Upload Video Berhasil",
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            error: "Ada masalah pada server, mohon contact admin",
          });
          console.log(err);
        });
    }
  };
  render() {
    if (this.props.email === "") {
      return <Redirect to="/" />;
    }
    return (
      <div className="container">
        <div className="row">
          <div className="offset-3 col-6 mt-5">
            <div className="card p-3">
              <h3>Upload Video</h3>
              {this.state.error ? (
                <div className="alert alert-danger">{this.state.error}</div>
              ) : null}
              {this.state.success ? (
                <div className="alert alert-success">{this.state.success}</div>
              ) : null}
              <input
                ref={(Title) => (this.Title = Title)}
                type="text"
                placeholder="Masukkan judul video"
                maxLength={255}
                className="form-control my-3"
              />
              {/* <textarea ref={LinkVideo => (this.LinkVideo = LinkVideo)} placeholder='Masukkan Link' className='form-control mb-3'></textarea> */}
              <CustomInput
                type="file"
                label={this.state.fileImageName}
                onChange={this.handleImage}
                className="mb-3"
              />
              <label htmlFor="">Link Video encrypt by SproutVideo</label>
              <CustomInput
                type="file"
                label={this.state.fileVideoName}
                onChange={this.handleVideo}
                className="mb-3"
              />
              {this.state.showUpload ? (
                <>
                  <div className="text-center mt-3">
                    {this.state.uploadProgress}%{" "}
                    {this.state.uploadProgress === 100 ? " Done" : null}
                  </div>
                  <Progress value={this.state.uploadProgress} />
                </>
              ) : null}
              <button
                disabled={this.state.loading}
                className="mt-3 btn btn-info form-control"
                style={{
                  cursor: `${this.state.loading ? "not-allowed" : "pointer"}`,
                }}
                onClick={this.SendDataToServer}
              >
                {this.state.loading ? "Loading..." : "Upload Video"}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  return {
    email: auth.email,
  };
};

export default connect(mapStateToProps)(Login);
