import React, { Component } from 'react';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavbarText
  } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { userLogOut } from '../support/redux/actions';

class Header extends Component {
    state = {
        isOpen: false,
        isLogOut: false
    }
    userLogOut = () => {
        this.props.userLogOut();
        this.setState({
            isLogOut: true
        })
    }
    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }
    render() {
        return (
            <div>
                {
                    this.state.isLogOut ?
                    <Redirect to='/' /> 
                    :
                    null
                }
              <Navbar color="info" light expand="md">
                <NavbarBrand href="/" className='text-white'>Website Video Streaming</NavbarBrand>
                <NavbarToggler onClick={this.toggle} />
                <Collapse isOpen={this.state.isOpen} navbar>

                    {
                      this.props.email !== '' ?
                      <Nav className="ml-auto" navbar>
                        <NavItem>
                            <NavLink className='text-white'>Halo, {this.props.nama}</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className='text-white' onClick={this.userLogOut} style={{ cursor: 'pointer'}}>Log Out</NavLink>
                        </NavItem>
                      </Nav>
                    :
                    <Nav className="ml-auto" navbar>
                        <NavItem>
                            <NavLink className='text-white' href="/editor">Contoh Editor</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className='text-white' href="/login">Login</NavLink>
                        </NavItem>
                      </Nav>
                    
                  }
                 
                  
                </Collapse>
              </Navbar>
            </div>
        )
    }
}

const mapStateToProps = ({ auth }) => {
    return {
        nama: auth.nama,
        email: auth.email
    }
}

export default connect(mapStateToProps, { userLogOut })(Header);

