import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { userLogin } from '../support/redux/actions';

class Login extends Component {
    state = { 
        error: ''
    }
    handleLoginUser = () => {
        this.setState({
            error: ''
        })
        console.log(this.Email.value,"email")
        console.log(this.Password.value,"Password")
        // if(this.Email.value === '' || this.Password.value === '') {
        //     this.setState({
        //         error: 'Harap Form Diiisi'
        //     })
        // }
        this.props.userLogin(this.Email.value, this.Password.value)
    }
    render() {
        if(this.props.email !== '') {
            return (
                <Redirect to='/' />
            )
        }
        return (
            <div className='container'>
                <div className='row'>
                    <div className='offset-3 col-6 mt-5'>
                        <div className='card p-3'>
                            <h3>Login</h3>
                            {
                                this.state.error !== '' ?
                                <div className='alert alert-danger'>{this.state.error}</div>
                                :
                                null
                            }
                            <input ref={Email => (this.Email = Email)} type="email" className='form-control my-3' placeholder='Masukan email' />
                            <input ref={Password => (this.Password = Password)} type="password" className='form-control mb-3' placeholder='Masukan Password' />
                            <button className='form-control btn btn-success' onClick={() => this.handleLoginUser()}>
                                Login
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ auth }) => {
    return {
        email: auth.email
    }
}

export default connect(mapStateToProps, { userLogin })(Login);