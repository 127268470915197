import React, { Component } from "react";
import {
  Button,
  Collapse,
  ListGroup,
  ListGroupItem,
  UncontrolledCollapse,
} from "reactstrap";
import axios from "axios";
import { Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import querystring from "query-string";
import Quiz from "react-quiz-component/lib/Quiz.js";

import lodash from "lodash";

import ParseToJSX from "html-react-parser";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

// test player
// import ReactPlayer from 'react-player';

import { URL_API } from "../support/url/api_url";

const quiz = {
  quizTitle: "React Quiz Component Demo",
  quizSynopsis:
    "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim",
  questions: [
    {
      question:
        "How can you access the state of a component from inside of a member function?",
      questionType: "text",
      questionPic: "https://dummyimage.com/600x400/000/fff&text=X", // if you need to display Picture in Question
      answerSelectionType: "single",
      answers: [
        "this.getState()",
        "this.prototype.stateValue",
        "this.state",
        "this.values",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      point: "20",
    },
    {
      question: "ReactJS is developed by _____?",
      questionType: "text",
      answerSelectionType: "single",
      answers: ["Google Engineers", "Facebook Engineers"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      point: "20",
    },
    {
      question: "ReactJS is an MVC based framework?",
      questionType: "text",
      answerSelectionType: "single",
      answers: ["True", "False"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      point: "10",
    },
    {
      question: "Which of the following concepts is/are key to ReactJS?",
      questionType: "text",
      answerSelectionType: "single",
      answers: [
        "Component-oriented design",
        "Event delegation model",
        "Both of the above",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      point: "30",
    },
    {
      question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
      questionType: "photo",
      answerSelectionType: "single",
      answers: [
        "https://dummyimage.com/600x400/000/fff&text=A",
        "https://dummyimage.com/600x400/000/fff&text=B",
        "https://dummyimage.com/600x400/000/fff&text=C",
        "https://dummyimage.com/600x400/000/fff&text=D",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      point: "20",
    },
    {
      question: "What are the advantages of React JS?",
      questionType: "text",
      answerSelectionType: "multiple",
      answers: [
        "React can be used on client and as well as server side too",
        "Using React increases readability and makes maintainability easier. Component, Data patterns improves readability and thus makes it easier for manitaining larger apps",
        "React components have lifecycle events that fall into State/Property Updates",
        "React can be used with any other framework (Backbone.js, Angular.js) as it is only a view layer",
      ],
      correctAnswer: [1, 2, 4],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      point: "20",
    },
  ],
};

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      redirectPage: "",
      otp: null,
      playbackInfo: null,
      subscription: null,
      openModalSubscription: false,
      onlineCourses: {
        onlinecourses_01: {
          status: false,
          session: {
            session_01: {
              status: false,
            },
          },
        },
        onlinecourses_02: {
          status: false,
          session: {
            session_01: {
              status: false,
            },
          },
        },
      },

      linkVideo: "",
      tempLinkVideo: "",
      quizVideo: "",
      tempQuizVideo: "",
      descriptionVideo: "",
      titleVideo: "",
      idVideo: "",
      securityToken: "",
      iframeVideo: (
        <iframe
          class="sproutvideo-player"
          src="https://videos.sproutvideo.com/embed/799cdcbf111ce2c7f0/d0b4c521ec81a6bf"
          style="position:absolute;width:100%;height:100%;left:0;top:0"
          frameborder="0"
          allowfullscreen
        ></iframe>
      ),
    };
    // this.handleEnter = this.handleEnter.bind(this);
    // this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  //   videoComponent = () => {
  //     <div style="position:relative;height:0;padding-bottom:56.25%"><iframe class='sproutvideo-player' src='https://videos.sproutvideo.com/embed/${resultVideo.data.id}/${resultVideo.data.security_token}' style='position:absolute;width:100%;height:100%;left:0;top:0' frameborder='0' allowfullscreen></iframe></div>
  //   }

  componentDidMount() {
    let slug = this.props.location.pathname.replace(/[/]/g, "");
    const token = localStorage.getItem("token");
    const options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .post(`${URL_API}/user/checkVideos`, { slug })
      .then((res) => {
        if (res.data.result === null) {
          this.setState({
            redirectPage: "404",
          });
        } else {
          // this.setState({
          //     data: res.data.result
          // });

          console.log(res.data.result);

          let videoProgram = res.data.result[0].title;
          axios
            .post(
              `${URL_API}/user/checkSubscription`,
              { videoProgram },
              options
            )
            .then((result) => {
              console.log(res.data.result);

              // const { module_name, module_no, topic_name, session_name} = res.data.result
              // let module_new = lodash(res.data.result).groupBy((module) => module.module_name).sortBy(group => group.module_no).value()
              // let module = lodash.groupBy(res.data.result, (module) => module.module_name)
              // let session = lodash.groupBy(res.data.result, (session) => session.session_name)
              // console.log(module_new)
              // console.log(session)

              // let obj = {
              //     module: [
              //         {
              //             session: [
              //                 session
              //             ]
              //         }
              //     ]
              // }
              // console.log(obj.module[0])
              // console.log(obj.module[0].session[0])
              // obj[`module_name_${module_no}`] = false
              // obj['session_name'] = false
              // obj[''] = false
              console.log(res.data.result[0].title);
              if (result.data.message !== "Not Subscribe") {
                this.setState({
                  subscription: true,
                  menuSection: {},
                  data: [],
                  titleVideo: res.data.result[0].title,
                });
              } else {
                this.setState({
                  subscription: false,
                  data: [],
                  titleVideo: res.data.result[0].title,
                });
              }
            })
            .catch((err) => {
              console.log(err);
              this.setState({
                subscription: false,
                data: [],
              });
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  playVideo = () => {
    console.log("Mainkan video");
  };

  openSubscription = (params) => {
    if (params) {
      return (
        <Modal
          isOpen={this.state.openModalSubscription}
          toggle={() => this.setState({ openModalSubscription: false })}
        >
          <ModalHeader
            toggle={() => this.setState({ openModalSubscription: false })}
          >
            Subscribe video berhasil
          </ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-12">
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/f6/OOjs_UI_icon_check-constructive.svg/1024px-OOjs_UI_icon_check-constructive.svg.png"
                  alt="check"
                  style={{ width: "50px" }}
                />
                <p>Terima kasih telah subscribe video</p>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              className="btn btn-danger"
              onClick={() =>
                this.setState({
                  openModalSubscription: false,
                  subscription: true,
                })
              }
            >
              Tutup
            </button>
          </ModalFooter>
        </Modal>
      );
    }
  };

  subscribeVideo = () => {
    const token = localStorage.getItem("token");
    const options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    // let videoId = this.state.data.id
    console.log(this.state.titleVideo);

    axios
      .post(
        `${URL_API}/user/subcriptionVideo`,
        { programName: this.state.titleVideo },
        options
      )
      .then((res) => {
        console.log(this.state.titleVideo);
        console.log(res);
        this.setState({
          openModalSubscription: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  renderCollapse = (name) => {
    return <div />;
  };

  getDataVideos = (topic_name) => {
    let slug = this.props.location.pathname.replace(/[/]/g, "");
    const token = localStorage.getItem("token");
    const options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let obj = {
      // topic_name: "",
      slug,
    };

    axios
      .post(`${URL_API}/user/getVideos`, obj, options)
      .then((res) => {
        console.log(res.data);
        // console.log(res.data.result.locationPath);
        // let idVideoPlayer = res.data.result.locationPath
        //   .split("src=")[1]
        //   .split("/")[4];
        this.setState({
          linkVideo: "Ada",
          // descriptionVideo: res.data.result.video_description,
          idVideo: res.data.result.videoSproutId,
          securityToken: res.data.result.securityTokenSprout,
          tempQuizVideo: res.data.result.quizLocation,
          tempLinkVideo: "",
          quizVideo: "",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  eventPlayer = (idVideo, iframe) => {
    if (idVideo !== "") {
      const svPlayer = window.SV;
      console.log(svPlayer);

      console.log(idVideo);

      var player = svPlayer.Player({ videoId: `${idVideo}`, target: iframe });
      console.log(player);
    }
  };

  showQuiz = () => {
    const tempVideoLink = this.state.linkVideo;
    const quizLink = this.state.tempQuizVideo;

    this.setState({
      linkVideo: "",
      tempLinkVideo: tempVideoLink,
      quizVideo: quizLink,
      tempQuizVideo: "",
    });
  };

  backToVideo = () => {
    const VideoLink = this.state.tempLinkVideo;
    const tempQuizLink = this.state.quizVideo;

    this.setState({
      linkVideo: VideoLink,
      tempLinkVideo: "",
      quizVideo: "",
      tempQuizVideo: "",
    });
  };

  renderPlatform = (locationPath, videoId, securityToken) => {
    return (
      <div className="row">
        <div className="col-4">
          <ListGroup>
            <ListGroupItem className="btn text-left" id="module_01">
              Module 01: <br />
              Programming Fundamentals &raquo;
            </ListGroupItem>
            <UncontrolledCollapse toggler="#module_01">
              <ListGroup>
                <ListGroupItem
                  id="module_01_session_01"
                  className="btn text-left pl-4"
                >
                  Session 01: <br />
                  Data Types &raquo;
                </ListGroupItem>
                <UncontrolledCollapse toggler="#module_01_session_01">
                  <ListGroup>
                    <ListGroupItem
                      className="btn text-left pl-5"
                      onClick={() => this.getDataVideos("Strings")}
                    >
                      Topic 01: Strings
                    </ListGroupItem>
                  </ListGroup>
                </UncontrolledCollapse>
                <ListGroupItem
                  id="module_01_session_02"
                  className="btn text-left pl-4"
                >
                  Session 02: <br />
                  Loop &raquo;
                </ListGroupItem>
                <UncontrolledCollapse toggler="#module_01_session_02">
                  <ListGroup>
                    <ListGroupItem
                      className="btn text-left pl-5"
                      onClick={() => this.getDataVideos("Do While")}
                    >
                      Topic 02: Do While
                    </ListGroupItem>
                  </ListGroup>
                </UncontrolledCollapse>
              </ListGroup>
            </UncontrolledCollapse>
            <ListGroupItem className="btn text-left" id="module_02">
              Module 02: <br />
              Front End Programming &raquo;
            </ListGroupItem>
            <UncontrolledCollapse toggler="#module_02">
              <ListGroup>
                <ListGroupItem
                  id="module_02_session_03"
                  className="btn text-left pl-4"
                >
                  Session 03: <br />
                  HTML &raquo;
                </ListGroupItem>
                <UncontrolledCollapse toggler="#module_02_session_03">
                  <ListGroup>
                    <ListGroupItem
                      className="btn text-left pl-5"
                      onClick={() => this.getDataVideos("HTML DOM")}
                    >
                      Topic 03: HTML DOM
                    </ListGroupItem>
                  </ListGroup>
                </UncontrolledCollapse>
              </ListGroup>
            </UncontrolledCollapse>
          </ListGroup>
        </div>
        <div className="col-8">
          {this.state.linkVideo !== "" ? (
            <div>
              {/* {ParseToJSX(this.state.linkVideo)} */}
              <div
                style={{
                  position: "relative",
                  height: 0,
                  paddingBottom: "56.25%",
                }}
              >
                <iframe
                  className="sproutvideo-player"
                  src={`https://videos.sproutvideo.com/embed/${this.state.idVideo}/${this.state.securityToken}`}
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    left: 0,
                    top: 0,
                  }}
                  // title={`${titleVideo}`}
                  frameBorder={0}
                  allowFullScreen
                />
              </div>

              <div className="mt-4">
                <p className="font-weight-bold">
                  Description: {this.state.descriptionVideo}
                </p>
              </div>
              <div className="row">
                <div className="col-6">
                  <button
                    className="btn btn-primary"
                    onClick={() => this.showQuiz()}
                  >
                    Take a Quiz
                  </button>
                </div>
              </div>
            </div>
          ) : null}

          {this.state.quizVideo !== "" ? (
            <div>
              {/* <iframe src="https://www.classmarker.com/online-test/start/?quiz=vga5ef41af848db0" width='100%' height='100%' frameborder="0"></iframe> */}
              {/* {ParseToJSX(this.state.quizVideo)} */}

              <Quiz quiz={quiz}></Quiz>
              <div className="mt-4">
                <button className="btn btn-warning" onClick={this.backToVideo}>
                  Back To Videos
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  renderData = () => {
    if (this.state.data) {
      const {
        title,
        locationPath,
        slug,
        thumbnailVideo,
        videoSproutId,
        securityTokenSprout,
      } = this.state.data;

      // const videoUrl = locationPath.split("src='")[1].split("' style=")[0]

      if (this.state.subscription !== null) {
        return (
          <div>
            {this.props.email !== "" ? (
              this.state.subscription ? (
                <h5>{this.state.titleVideo}</h5>
              ) : (
                <h6>
                  Untuk dapat melihat video ini, anda harus subscription
                  terlebih dahulu.
                </h6>
              )
            ) : null}
            {this.props.email !== "" ? (
              this.state.subscription ? (
                this.renderPlatform(
                  locationPath,
                  videoSproutId,
                  securityTokenSprout
                )
              ) : (
                // <iframe id={"VideoIdSprout"} src={videoUrl} frameborder="0"></iframe>
                <button
                  onClick={this.subscribeVideo}
                  className="btn btn-warning"
                >
                  Klik untuk melakukan Subscription
                </button>
              )
            ) : (
              <Link to="/login" className="btn btn-success">
                Anda harus login untuk melihat videonya
              </Link>
            )}
          </div>
        );
      }
    } else {
      return (
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      );
    }
  };
  render() {
    if (this.state.redirectPage === "404") {
      return <Redirect to="/not-found" />;
    }
    return (
      <div className="container">
        {/* {this.eventPlayer(this.state.idVideo, this.state.iframeVideo)} */}
        {this.openSubscription(this.state.openModalSubscription)}
        <div className="row">
          <div className="offset-1 col-10 mt-5">
            <Link to="/">{`<- Kembali ke halaman courses`}</Link>
            <div className="card p-3">{this.renderData()}</div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  return {
    email: auth.email,
  };
};

export default connect(mapStateToProps)(Login);
