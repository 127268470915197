import React, { Component } from 'react';
import { Button, Collapse } from 'reactstrap';
import axios from 'axios';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import querystring from 'query-string';


import ParseToJSX from 'html-react-parser';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
// test player
// import ReactPlayer from 'react-player';

import { URL_API } from '../support/url/api_url';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            redirectPage: '',
            otp: null,
            playbackInfo: null,
            subscription: null,
            openModalSubscription: false,
            menuSection: {}
        };
        // this.handleEnter = this.handleEnter.bind(this);
        // this.handleKeyPress = this.handleKeyPress.bind(this);
      }

    componentDidMount() {
        let slug = this.props.location.pathname.replace(/[/]/g, "")
        const token = localStorage.getItem("token");
        const options = {
              headers: {
                Authorization: `Bearer ${token}`,
              }
            };
        axios.post(`${URL_API}/user/checkVideos`, { slug })
        .then((res) => {
            
            if(res.data.result === null) {
                this.setState({
                    redirectPage: '404'
                })
            } 
            else
            {
                this.setState({
                    data: res.data.result
                });

                let videoId = res.data.result.id
                axios.post(`${URL_API}/user/checkSubscription`, { videoId }, options)
                .then((result) => {

                    const { module_name, module_no, topic_name, session_name} = res.data.result
                    let obj = {}
                    obj[`module_name_${module_no}`] = false
                    obj['session_name'] = false
                    // obj[''] = false
                    if(result.data.message !== "Not Subscribe") {
                        this.setState({
                            subscription: true,
                            menuSection: obj
                        })
                    } else {
                        this.setState({
                            subscription: false
                        })
                    }
                })
                .catch((err) => {
                    console.log(err)
                    this.setState({
                        subscription: false
                    })
                })
            }
        })
        .catch((err) => {
            console.log(err)
        })

       
    }

    playVideo = () => {
        console.log('Mainkan video')
    }

    openSubscription = (params) => {
        if(params) {
            return (
                <Modal isOpen={this.state.openModalSubscription} toggle={() => this.setState({ openModalSubscription: false })} >
                    <ModalHeader toggle={() => this.setState({ openModalSubscription: false })} >Subscribe video berhasil</ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-12">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/f6/OOjs_UI_icon_check-constructive.svg/1024px-OOjs_UI_icon_check-constructive.svg.png" alt="check" style={{width: '50px'}}/>
                                <p>Terima kasih telah subscribe video</p>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                    <button className='btn btn-danger' onClick={() => this.setState({ openModalSubscription: false, subscription: true })}  >Tutup</button>
                    </ModalFooter>
                </Modal>
            )
        }
    }   

    subscribeVideo = () => {
        const token = localStorage.getItem("token");
        const options = {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        };

        let videoId = this.state.data.id

        axios.post(`${URL_API}/user/subcriptionVideo`, { videoId }, options)
        .then((res) => {
            console.log(res)
            this.setState({
                openModalSubscription: true
            })
        })
        .catch((err) => {
            console.log(err)
        })
    }

    // onPlay = () => {


    //     let player = document.getElementById('VideoIdSprout')

    //     console.log(player)

    //     player.addEventListener('completed', () => {
    //         alert('Video Telah Selesai')
    //     })
        
    // }

    renderCollapse = (name) => {
        return (
            <div />
        )
    }

    renderPlatform = (locationPath) => {
        return (
            <div className='row'>
                <div className="col-4">

                </div>
                <div className='col-8'>

                </div>
            </div>
        )
    }

    renderData = () => {
        if(this.state.data) {
            const {
                title,
                locationPath,
                slug,
                thumbnailVideo
            } = this.state.data

            const videoUrl = locationPath.split("src='")[1].split("' style=")[0]

            if(this.state.subscription !== null) {
                return (
                    <div>
                        {
                           this.props.email !== "" ?
                            this.state.subscription ?
                            <h6>Judul: {title}</h6>
                            :
                            <h6>Untuk dapat melihat video ini, anda harus subscription terlebih dahulu.</h6>
                            :
                           null
                        }
                        {
                            this.props.email !== "" ?
                                this.state.subscription ?
                                ParseToJSX(locationPath)
                                :
                                <button onClick={this.subscribeVideo} className='btn btn-warning' >
                                    Klik untuk melakukan Subscription
                                </button>
                            :
                            <Link to='/login' className='btn btn-success'>
                                Anda harus login untuk melihat videonya
                            </Link>
                        }
                      
                    </div>
                )
            }
        } else {
            return (
                <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            )
        }
    }
    render() {
        if(this.state.redirectPage === '404') {
            return (
                <Redirect to='/not-found' />
            )
        }
        return (
            <div className='container'>
                {this.openSubscription(this.state.openModalSubscription)}
                <div className='row'>
                    
                    <div className='offset-1 col-10 mt-5'>
                    <Link to='/'>{`<- Kembali ke halaman courses`}</Link>
                        <div className='card p-3'>
                            {this.renderData()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ auth }) => {
    return {
        email: auth.email
    }
}

export default connect(mapStateToProps)(Login);