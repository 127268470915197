import React, { Component } from 'react';
import axios from 'axios';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { URL_API } from '../support/url/api_url';

class Login extends Component {
    state = {
        listVideos: null
    }
    componentDidMount() {
        const token = localStorage.getItem("token");
        const options = {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        };

        axios.get(`${URL_API}/user/allvideos`, options)
        .then((res) => {
            console.log(res.data.results)
            this.setState({
                listVideos: res.data.results
            })
        })
        .catch((err) => {
            console.log(err)
        })
    }

    renderAllVideos = () => {
        if(this.state.listVideos) {
            if(this.state.listVideos.length !== 0) {
                return this.state.listVideos.map((val) => {
                    return (
                        // <Link to={`/${val.slug}`} className='card p-2 mb-3'>
                        //     {val.title}
                        // </Link>
                        <div className='col-md-6'>
                            <div className="card">
                                <img className="card-img-top" src={`${URL_API}${val.thumbnail_video}`} alt={`${val.thumbnail_video}`} />
                                <div className="card-body">
                                <h5 className="card-title" style={{height: '60px'}}>{val.title}</h5>
                                <Link to={`/${val.slug}`} className='btn btn-primary'>
                                    Lihat Courses
                                </Link>
                                </div>
                            </div>
                        </div>
                    )
                })
            } 
            return (
                <div>
                    <h6>Tidak ada video</h6>
                </div>
            )
        }
        return (
            <div>
                <h6>Loading.....</h6>
            </div>
        )
    }

    render() {
       
        if(this.props.authChecked && this.props.email !== '') {
            return (
                <div className='container'>
                    <div className='row'>
                        <div className='offset-2 col-8 mt-5'>
                            <div className='card p-3'>
                                <h3>Available Courses</h3>
                                <div className="row">
                                    {this.renderAllVideos()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } 

        return <div />
    }
}

const mapStateToProps = ({ auth }) => {
    return {
        email: auth.email,
        authChecked: auth.authChecked
    }
}

export default connect(mapStateToProps)(Login);