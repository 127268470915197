import React, { Component } from 'react';
import { Switch, Route, withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { KeepLogin } from './support/redux/actions';

//Components 
import Header from './components/Header';
import LinkBreadCrumb from './components/LinkBreadCrumb';

//Pages
import Home from './pages/Home';
import Login from './pages/Login';
import Courses from './pages/Courses';
import UploadCourses from './pages/UploadCourses';
import VideoPage from './pages/Video';
import VideoPageNew from './pages/VideoNew';

class App extends Component {
  componentDidMount() {
    this.props.KeepLogin();
  }
  notFound = () => {
    return (
      <div className='container'>
        <div className='row'>
          <div className='col-12 text-center'>
            <h4>
              Halaman Tidak Ditemukan
            </h4>
          </div>
        </div>
      </div>
    )
  }
  render() {
    if(!this.props.authChecked) {
      return (
        <div>
          Loading..........................................
        </div>
      )
    }
    return (
      <div>
        <Header />
        {/* {
          this.props.email !== '' ?
          <LinkBreadCrumb />
          :
          null
        } */}
        <Switch>
          <Route path='/' component={Home} exact/>
          <Route path='/login' component={Login} exact />
          {/* <Route path='/courses' component={Courses} exact /> */}
          <Route path='/upload' component={UploadCourses} exact/>
          <Route path='/not-found' component={this.notFound} exact/>
          {/* <Route path='/:slug' component={VideoPage} /> */}
          <Route path='/:slug' component={VideoPageNew} />
          <Route component={this.notFound} />
        </Switch>
      </div>
    )
  }
}

const mapStateToProps = ({ auth }) => {
  return {
    email: auth.email,
    authChecked: auth.authChecked
  };
};

export default withRouter(connect(mapStateToProps, { KeepLogin })(App));