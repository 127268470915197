import React, { Component } from "react";
import Courses from "./Courses";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import ApiCalendar from "react-google-calendar-api";

class Home extends Component {
  state = {
    judulEvent: "Purwadhika Online Courses Q&A by our mentor",
    description:
      "Q&A ini bertujuan untuk siswa yang ingin bertanya langsung kepada instruktur.",
    startDate: new Date("2020-07-7"),
    endDate: new Date("2020-07-7"),
  };
  onClickDate = () => {
    // ApiCalendar.createEvent({
    //     startDate: this.state.startDate,
    //     endDate: this.state.endDate,
    //   })
    //     .then((res) => {
    //       console.log(res);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // ApiCalendar.setCalendar("Tes")
    //   .then((res) => {
    //     console.log(res);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    // ApiCalendar.setCalendar()
    //   .then((res) => {
    //     console.log(res);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    ApiCalendar.handleAuthClick();
  };
  render() {
    return (
      <div className="container">
        <h1>Courses Video</h1>
        {this.props.email !== "" ? null : (
          <Link className="mt-3 btn btn-success" to="/login">
            Sign In
          </Link>
        )}
        <button onClick={() => this.onClickDate()} className="btn btn-primary">
          Save the date
        </button>
        <Courses />
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  return {
    email: auth.email,
  };
};

export default connect(mapStateToProps)(Home);
